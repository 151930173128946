<template>
  <div>
    <div class="wrap">
      <div class="con_top">
        <h2><span>■</span> 공지수정</h2>
      </div>
      <div class="con">
        <div class="con_table">
          <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
            <tbody v-for="(item, index) of boardList" :key="index">
              <tr>
                <th style="width:111px;">제목</th>
                <td class="left" style="padding-left: 0"><input type="text" v-model="item.real_title" class="ml-20 w-94per" name="title" /> <br /></td>
                <th style="width:111px;">중요</th>
                <td class="left" style="padding-left: 1em">중요공지 표시 <input name="중요표시" v-model="item.board_important" type="checkbox" true-value="Y" false-value="N" /></td>
              </tr>
              <tr>
                <th style="width:111px;">노출 구분</th>
                <td class="left" style="padding-left: 1em">
                  <input name="공용" v-model="item.board_gubun" value="공용" type="radio" />공용 <input name="본원센터" v-model="item.board_gubun" value="본원센터" class="ml-3" type="radio" />본원센터
                  <input name="협약센터" v-model="item.board_gubun" value="협약센터" class="ml-3" type="radio" />협약센터
                </td>
                <th style="width:111px;">첨부파일</th>
                <td class="left" style="padding-left: 0">
                  <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.boardFile[0].click()">파일 선택</button>
                  <input v-show="false" ref="boardFile" type="file" @change="uploadBoardFile($event, 'boardFile')" />
                  <span v-if="boardFile === ''" class="ml-20" style="cursor:pointer" @click="removeBoardFile()">{{ item.board_file_name }}</span>
                  <span v-else class="ml-20" style="cursor:pointer" @click="removeBoardFile()">{{ boardFile.name }}</span>
                </td>
              </tr>
              <tr>
                <th style="width:111px">내용</th>
                <td class="left" colspan="3">
                  <quill-editor v-model="item.board_contents" />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btns3 mgB80">
            <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
            <a class="btn_cancle pointer" @click="updateBoard()">수정</a>
          </div>
        </div>
      </div>
      <div id="result"></div>
    </div>
  </div>
</template>

<script>
import { EmptyCheck } from "@/utils/validation.js";
//에디터 컴퍼넌트
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
export default {
  name: "HappycrmFrontModify",

  components: {
    quillEditor,
  },

  data: () => ({
    idx: -1,
    boardList: {},
    boardTitle: "",
    baordFileUrl: "",
    boardFileName: "",
    boardFile: "",
    chStatus: 0,
    name: "",
    board_important: "",
    page: 1,
    pagesize: 50,
  }),

  mounted() {
    const { idx } = this.$route.params;
    this.getBoardList(idx);
  },

  methods: {
    removeBoardFile() {
      this.boardFile = "";
      this.boardList[0].board_file_name = "";
      this.boardList[0].board_file_url = "";
      this.chStatus = 2;
    },
    // 이전 페이지로 이동
    cancel() {
      history.back();
    },
    // 첨부 파일 업로드
    uploadBoardFile(event, fileType) {
      // 포맷 검사
      var allowFormat = ["jpg", "jpeg", "png", "gif", "JPG", "JPEG", "PNG", "GIF", "pdf", "hwp", "xlsx", "docx", "PDF", "HWP", "XLSX", "DOCX"];
      var checked = false;
      for (var item of allowFormat) {
        if (event.target.files[0].name.includes(item)) {
          checked = true;
          break;
        } else {
          continue;
        }
      }
      if (checked) {
        // 용량 검사
        if (event.target.files[0].size > 1048576 * 3) {
          alert("3MB 이하만 업로드 가능합니다.");
          this.$refs[fileType].value = "";
          return false;
        }
        this[fileType] = event.target.files[0];
        this.$refs[fileType].value = "";
      } else {
        alert("파일 포맷은 jpg, gif, png, jpeg, pdf, hwp, xlsx, docx 형식만 가능합니다.");
        this.$refs[fileType].value = "";
        return false;
      }
    },

    // 게시판 리스트 출력
    getBoardList(idx) {
      var params = {
        idx: idx,
      };
      this.axios
        .get(`/api/v1/board/${idx}`, { params: params })
        .then((res) => {
          if (res.data.err === 0) {
            this.boardList = res.data.boardList;
          }
          // 센터사진 있는 경우 값 할당
          for (var item of Object.keys(res.data.boardList)) {
            if (typeof this[item] !== "undefined") {
              if (item === "centerRegName") {
                continue;
              }
              if (item === "baordFileUrl") {
                if (res.data.boardList.board_file_url && res.data.boardList.board_file_url.includes("object.ncloudstorage.com")) {
                  this.baordFileUrl = res.data.boardList.board_file_url;
                  this.boardFileName = res.data.boardList.boardFileName;
                }
                continue;
              }
              this[item] = res.data.boardList[item];
            }
          }
        })
        .catch((err) => {
          console.log(err);
          //     console.log(err)
          //     localStorage.removeItem('accessToken')
          //     localStorage.removeItem('id')
          //     alert('세션이 만료되어 로그인 페이지로 이동합니다.')
          //     this.$router.push('/login')
          // })
          // .finally(()=>{
          //     this.loading = false;
        });
    },

    updateBoard() {
      if (this.boardFile.name !== undefined) {
        // 파일 추가/수정한거
        this.chStatus = 1;
      } else {
        if (this.boardFile === "" && this.boardList[0].board_file_name == "") {
          this.chStatus = 1;
          this.boardFile = null;
        } else {
          // 변경 없음
          this.chStatus = -1;
          this.boardFile = null;
        }
      }
      // alert("##this.boardFile" + this.boardFile + " ##this.chStatus" + this.chStatus
      // + " ##this.boardList[0].board_title"+ this.boardList[0].board_file_name)

      // 빈값 체크
      if (!EmptyCheck(this.boardList[0].board_title, "제목을")) {
        return false;
      }

      if (!EmptyCheck(this.boardList[0].board_contents, "내용을")) {
        return false;
      }

      var params = new FormData();
      params.append("realTitle", this.boardList[0].real_title);
      params.append("boardTitle", this.boardList[0].board_title);
      params.append("boardContents", this.boardList[0].board_contents);
      params.append("boardGubun", this.boardList[0].board_gubun);
      params.append("boardImportant", this.boardList[0].board_important);
      // 파일 빈 binary로 추가해놓기
      params.append("boardFile", new Blob());
      if (this.boardFile !== "") {
        params.delete("boardFile");
        params.append("boardFile", this.boardFile);
      }
      if (this.btnDisabled) {
        alert("저장중입니다. 잠시만 기다려주세요.");
        return false;
      }
      params.append("chStatus", this.chStatus);
      this.btnDisabled = true;
      this.axios
        .put(`/api/v1/board/${this.boardList[0].idx}`, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          alert("수정 완료.");
          this.$router.push(`/origin/notice_detail/${this.boardList[0].idx}`);
        })
        .catch((err) => {
          alert(err);
          console.log(err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
